<script setup lang="ts">
// @ts-nocheck
// needed because of #header and #content slots in <component>
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';
import type { UseBreakpointsReturn } from '@vueuse/core';
import { useChangeCase } from '@vueuse/integrations/useChangeCase';

const { navigationElements } = useNavigation({ type: 'footer-navigation' });
const { navigationElements: serviceNavigationElements } = useNavigation({ type: 'service-navigation' });
const breakpoints: UseBreakpointsReturn = inject('breakpoints') as UseBreakpointsReturn;
const smallerThanLg = breakpoints.smaller('lg');
const component = computed(() => (smallerThanLg.value ? 'SharedAccordion' : 'LayoutFooterNavigationDesktop'));
const skeletonElements = computed(() => {
    if (
        serviceNavigationElements.value &&
        serviceNavigationElements.value?.length > 0 &&
        navigationElements.value?.length > 0
    ) {
        return [{ id: 'service', children: serviceNavigationElements.value }, ...navigationElements.value];
    }

    return navigationElements.value ?? [];
});

const getFooterId = (id: string): string => {
    return useChangeCase('footer-' + id, 'kebabCase')?.value;
};

withDefaults(
    defineProps<{
        allAccordionsClosed?: boolean;
    }>(),
    {
        allAccordionsClosed: false,
    },
);
</script>

<template>
    <ClientOnly>
        <div
            v-if="serviceNavigationElements && serviceNavigationElements.length > 0"
            class="border-b-1 lg:border-b-none"
        >
            <component
                :is="component"
                id="footer-service"
                :open="false"
            >
                <template #header>
                    <div class="text-white lg:mr-7 lg:text-xl lg:font-bold lg:text-gray-200 lg:uppercase">
                        {{ $t('service.headline') }}
                    </div>
                </template>

                <template #content>
                    <div class="text-white lg:py-4">
                        <ul class="list-none pl-0">
                            <li
                                v-for="navigationChild in serviceNavigationElements"
                                :key="navigationChild.id"
                            >
                                <LayoutCategory
                                    :category="navigationChild"
                                    class="hover:underline block py-1.5 lg:py-0.5"
                                />
                            </li>
                        </ul>
                    </div>
                </template>

                <template #icon="{ isOpen }">
                    <LazySvgoAngleDown
                        v-if="smallerThanLg"
                        class="text-white h-5 w-auto"
                        :class="{
                            'rotate-180': isOpen,
                            'rotate-0': !isOpen,
                        }"
                    />
                </template>
            </component>
        </div>

        <div
            v-for="(navigationElement, index) in navigationElements ?? []"
            :key="navigationElement.id"
            class="border-b-1 lg:border-b-none"
        >
            <component
                :is="component"
                :id="getFooterId(getTranslatedProperty(navigationElement, 'name'))"
                :open="navigationElements && index === navigationElements.length - 1 && !allAccordionsClosed"
            >
                <template #header>
                    <div class="text-white lg:mr-7 lg:text-xl lg:font-bold lg:text-gray-200 lg:uppercase">
                        {{ getTranslatedProperty(navigationElement, 'name') }}
                    </div>
                </template>

                <template #content>
                    <div
                        v-if="navigationElement.childCount > 0"
                        class="text-white lg:py-4"
                    >
                        <ul class="list-none pl-0">
                            <li
                                v-for="navigationChild in navigationElement.children"
                                :key="navigationChild.id"
                            >
                                <LayoutCategory
                                    :category="navigationChild"
                                    class="hover:underline block py-1.5 lg:py-0.5"
                                />
                            </li>
                        </ul>
                    </div>
                </template>

                <template #icon="{ isOpen }">
                    <LazySvgoAngleDown
                        v-if="smallerThanLg"
                        class="text-white h-5 w-auto"
                        :class="{
                            'rotate-180': isOpen,
                            'rotate-0': !isOpen,
                        }"
                    />
                </template>
            </component>
        </div>

        <template #fallback>
            <LayoutFooterNavigationSkeleton :nav-elements="skeletonElements" />
        </template>
    </ClientOnly>
</template>
